define("discourse/plugins/discourse-algolia/discourse/initializers/discourse-algolia", ["exports", "I18n", "virtual-dom", "discourse/lib/url", "discourse/lib/plugin-api", "discourse/plugins/discourse-algolia/discourse/initializers/discourse-autocomplete", "@ember/runloop"], function (_exports, _I18n, _virtualDom, _url, _pluginApi, _discourseAutocomplete, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-algolia",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.8", api => {
        api.createWidget("algolia", {
          tagName: "li.algolia-holder",
          didRenderWidget() {
            if (this.siteSettings.algolia_enabled && this.siteSettings.algolia_autocomplete_enabled) {
              (0, _runloop.schedule)("afterRender", () => {
                document.body.classList.add("algolia-enabled");
                const searchBox = document.querySelector("#search-box");
                searchBox && searchBox.addEventListener("focus", this._selectSearchBoxContent);
                this._search = _discourseAutocomplete.default._initialize({
                  algoliaApplicationId: this.siteSettings.algolia_application_id,
                  algoliaSearchApiKey: this.siteSettings.algolia_search_api_key,
                  imageBaseURL: "",
                  debug: document.location.host.indexOf("localhost") > -1,
                  onSelect(event, suggestion) {
                    _url.default.routeTo(suggestion.url);
                  }
                });
              });
            }
          },
          willRerenderWidget() {
            const searchBox = document.querySelector("#search-box");
            searchBox && searchBox.removeEventListener("focus", this._selectSearchBox);
            this._search && this._search.autocomplete.destroy();
          },
          html() {
            return [(0, _virtualDom.h)("form", {
              action: "/community/search",
              method: "GET"
            }, [(0, _virtualDom.h)("input.aa-input#search-box", {
              name: "q",
              placeholder: _I18n.default.t("discourse_algolia.search_box_placeholder"),
              autocomplete: "off"
            })])];
          },
          _selectSearchBoxContent(event) {
            event.target.select();
          }
        });
        api.decorateWidget("header-icons:before", function (helper) {
          if (helper.widget.siteSettings.algolia_enabled && helper.widget.siteSettings.algolia_autocomplete_enabled) {
            return helper.attach("algolia");
          }
        });
      });
    }
  };
});